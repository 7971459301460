import Logo from "@/assets/images/logo.svg";
import Menu from "@/assets/images/Menu.svg";
import Close from "@/assets/images/Close.svg";
import User from "@/assets/images/User.png";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import {
  dataUserSelect,
  handleSignOut,
  isAuthSelect,
  updateDataUser,
} from "@/state/authSlice";
import "./style.scss";
import { Dropdown } from "antd";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { UrlInternal } from "@/constant/endpointRoute";
import WithDraw from "../WithDraw";
import Deposit from "../Deposit";
import MyAccount from "./MyAccount";
import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";
import { formatPrice } from "@/utils/formatPrice";
import CopyToClipboard from "react-copy-to-clipboard";
import Copy from "@/assets/images/Copy.svg";
import { message } from "antd";
import { scrollToSection } from "@/utils/scrollToSection";
import eyeShow from "@/assets/img/eye-show.png";
import eyeHidden from "@/assets/img/eye-hidden.png";
import rightImg from "@/assets/img/right.png";
import { envConfig } from "@/constant/envConfig";

const dataMenu = [
  {
    label: "About",
    href: UrlInternal.ABOUT,
    url: "",
  },
  {
    label: "Service",
    href: "/",
    url: "Service",
  },
  {
    label: "Earn",
    href: "/",
    url: "Earn",
  },
  // {
  //   label: "FAQ",
  //   href: "/",
  // },
  {
    label: "Membership NFT",
    href: UrlInternal.MEMBERSHIP_NFT,
    url: "",
  },
  {
    label: "Support",
    href: "/",
    url: "support",
  },
];

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isAuth = useAppSelector(isAuthSelect);
  const dataUser = useAppSelector(dataUserSelect);

  const [isActive, setIsActive] = useState(false);
  const [isDeposit, setIsDeposit] = useState(false);
  const [isWithdraw, setIsWithdraw] = useState(false);
  const [isShowAccount, setIsShowAccount] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const menu = searchParams.get("menu");
  const location = useLocation();
  const [isEye, setIsEye] = useState(true);

  console.log("location", location);

  const getDetailUser = async () => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.user.details,
      });
      if (resp?.result) {
        dispatch(updateDataUser(resp.data));
      }
    } catch (error) {
      console.log();
    }
  };

  useEffect(() => {
    if (isAuth) getDetailUser();
  }, [isAuth]);

  const balanceUsdt = useMemo(() => {
    const findItem = dataUser?.balances?.find(
      (i: { asset_type: string }) => i?.asset_type === "USDT"
    );
    return findItem?.balance || 0;
  }, [dataUser]);

  const handleClickMenu = (url: string, href: string) => {
    if (href === "/") {
      if (location.pathname === "/") {
        scrollToSection(url);
      } else {
        navigate({
          pathname: UrlInternal.HOME,
          search: `?menu=${url}`,
        });
      }
    } else {
      navigate(href);
    }
  };

  useEffect(() => {
    if (menu) {
      setTimeout(() => {
        scrollToSection(menu);
      }, 500);
    }
  }, [menu]);

  const RenderUser = () => {
    return (
      <div className="dropdown-user min-w-[450px] py-[35px] px-[36px] border-solid border-white/30 border-[1px] rounded-[30px]">
        <div className="flex items-center justify-between">
          <div className="text-[18px] leading-[44px] font-[400] text-[#C0C0C0]">
            My account
          </div>
          <div className="text-[18px] leading-[44px] font-[400] text-[#C0C0C0]">
            {dataUser?.email}
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="text-[18px] leading-[44px] font-[400] text-[#C0C0C0] ">
            Referral code
          </div>
          <div className="text-[18px] leading-[44px] font-[400] text-[#C0C0C0]  flex gap-1">
            {dataUser?.referralCode}
            <CopyToClipboard
              text={dataUser?.referralCode}
              onCopy={() => message.success("Copied")}
            >
              <img src={Copy} alt="copy" className="cursor-pointer" />
            </CopyToClipboard>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="text-[18px] leading-[44px] font-[400] text-[#C0C0C0]">
            UID
          </div>
          <div className="text-[18px] leading-[44px] font-[400] text-[#C0C0C0] flex items-center gap-[9px]">
            {isEye ? (
              "***********************"
            ) : (
              <>
                {dataUser?.secretKeyBeInvited?.substring(0, 6)}...
                {dataUser?.secretKeyBeInvited?.substring(
                  dataUser?.secretKeyBeInvited.length - 9,
                  dataUser?.secretKeyBeInvited.length
                )}
              </>
            )}
            <span onClick={() => setIsEye(!isEye)}>
              <img src={isEye ? eyeShow : eyeHidden} alt="img" />
            </span>
            <CopyToClipboard
              text={dataUser?.secretKeyBeInvited}
              onCopy={() => message.success("Copied")}
            >
              <img src={Copy} alt="copy" className="cursor-pointer" />
            </CopyToClipboard>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <div className="text-[18px] font-[400] text-[#C0C0C0]">
            My Balance
          </div>
          <div className="text-[32px] font-[600] text-[#B0F127]">
            ${formatPrice(balanceUsdt)}
          </div>
        </div>
        <div className="flex gap-3 mt-[32px]">
          <div
            onClick={() => setIsDeposit(true)}
            className="flex items-center justify-center h-[50px] w-full text-center bg-[#B0F127] text-[#000] text-[14px] font-montserrat700 rounded-[48px] cursor-pointer"
          >
            Deposit
          </div>
          <div
            onClick={() => setIsWithdraw(true)}
            className="flex items-center justify-center h-[50px] w-full text-center bg-[#B0F127] text-[#000] text-[14px] font-montserrat700 rounded-[48px] cursor-pointer"
          >
            Withdraw
          </div>
          <div
            onClick={() => navigate(UrlInternal.CONVERT)}
            className="flex items-center justify-center h-[50px] w-full text-center bg-transparent text-[14px] font-montserrat700 rounded-[48px] cursor-pointer border border-solid border-[#B0F127] text-[#B0F127]"
          >
            Convert
          </div>
          <div
            onClick={() =>
              navigate({
                pathname: UrlInternal.HISTORY_EARN_PROFIT,
                search: `tab=DEPOSIT`,
              })
            }
            className="flex items-center justify-center h-[50px] w-full text-center bg-transparent text-[14px] font-montserrat700 rounded-[48px] cursor-pointer border border-solid border-[#B0F127] text-[#B0F127]"
          >
            History
          </div>
        </div>

        {dataUser?.isAgency && (
          <div
            className="mt-[30px] flex justify-end items-center gap-[8px] cursor-pointer"
            onClick={() => window.open(envConfig.DEFYLO_AGENCY)}
          >
            <div className="text-[18px] font-montserrat600 leading-none pb-[5px] border-0 border-b border-solid border-[#fff]">
              Go to agency page
            </div>
            <img src={rightImg} alt="img" />
          </div>
        )}
      </div>
    );
  };
  return (
    <>
      <WithDraw isVisible={isWithdraw} setCancel={() => setIsWithdraw(false)} />
      <Deposit isVisible={isDeposit} setCancel={() => setIsDeposit(false)} />

      <div className="fixed top-0 left-0 w-full z-[9999999] flex items-center justify-center bg-black py-6 md:py-8">
        <div className="max-w-[1314px] px-4 w-full flex items-center justify-between">
          <div
            className="cursor-pointer"
            onClick={() => navigate(UrlInternal.HOME)}
          >
            <img
              src={Logo}
              alt="logo"
              className="md:max-h-[42px] max-h-[26px]"
            />
          </div>
          <div className="md:flex flex-1 w-full max-w-[415px] justify-between hidden">
            {dataMenu.map((item, index) => {
              return (
                <div
                  key={index}
                  className="text-[16px] font-[400] cursor-pointer hover:opacity-80"
                  onClick={() => handleClickMenu(item?.url, item?.href)}
                >
                  {item?.label}
                </div>
              );
            })}
          </div>
          <div className="md:flex items-center hidden">
            {isAuth ? (
              <>
                <div
                  className="text-[16px] font-[600] mr-[32px] cursor-pointer hover:opacity-[0.7]"
                  onClick={() => dispatch(handleSignOut())}
                >
                  Logout
                </div>
                <div className="user-info relative">
                  <div
                    className="bg-[#B0F127] rounded-[50%] w-[50px] h-[50px] cursor-pointer flex items-center justify-center hover:opacity-80"
                    onClick={() => navigate(UrlInternal.MYPAGE)}
                  >
                    <img
                      src={User}
                      alt="user"
                      className="w-[32px] h-[32px] opacity-80"
                    />
                  </div>
                  {/* <Dropdown dropdownRender={RenderUser}>
                    <div className="bg-[#B0F127] rounded-[50%] w-[50px] h-[50px] cursor-pointer flex items-center justify-center hover:opacity-80">
                      <img
                        src={User}
                        alt="user"
                        className="w-[32px] h-[32px] opacity-80"
                      />
                    </div>
                  </Dropdown> */}
                  {/* {RenderUser()} */}
                </div>
              </>
            ) : (
              <>
                <div
                  className="text-[16px] font-[600] mr-[32px] cursor-pointer hover:opacity-[0.7]"
                  onClick={() => navigate(UrlInternal.LOGIN)}
                >
                  Login
                </div>
                <div
                  className="text-[16px] font-[600] bg-[#B0F127] py-2.5 px-5 rounded-[48px] text-[#060606] cursor-pointer hover:opacity-80"
                  onClick={() => navigate(UrlInternal.SIGNUP)}
                >
                  Register -{" "}
                  <span className="text-[14px] font-[400] opacity-[0.6]">
                    It’s free
                  </span>
                </div>
              </>
            )}
          </div>
          <div
            className="md:hidden cursor-pointer hover:opacity-80 transition-transform duration-300 h-8"
            onClick={() => setIsActive((prev) => !prev)}
          >
            {isActive ? (
              <img
                src={Close}
                alt=""
                className="transform rotate-180 transition-transform duration-300"
              />
            ) : (
              <img
                src={Menu}
                alt=""
                className="transform rotate-0 transition-transform duration-300"
              />
            )}
          </div>
          {
            <div
              //       className={`absolute bg-[#060606] w-full left-0 top-[100%]
              //  flex flex-col justify-between
              //  transform
              //  transition-transform transition-opacity duration-500 ease-in-out
              //  px-6 py-9 h-[calc(100vh-80px)] ${isActive
              //           ? "translate-y-0 opacity-100"
              //           : "-translate-y-[110%] opacity-0"
              //         }`}
              className={`absolute bg-[#060606] w-full left-0 top-[100%] 
          flex flex-col justify-between md:hidden
          transition-all duration-500 ease-in-out 
          px-6 py-9 h-[calc(100vh-80px)] ${
            isActive
              ? "pointer-events-auto opacity-100"
              : "pointer-events-none opacity-0"
          }`}
            >
              <div>
                {dataMenu.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="text-[16px] font-[400] cursor-pointer hover:opacity-80 mb-6"
                      onClick={() => {
                        handleClickMenu(item?.url, item?.href);
                        setIsActive(false);
                      }}
                    >
                      {item?.label}
                    </div>
                  );
                })}
              </div>

              {isAuth ? (
                <div className="">
                  <div
                    className="text-[16px] font-[600] bg-[#B0F127] py-2.5 px-5 rounded-[48px] text-[#060606] cursor-pointer hover:opacity-80 mb-4 text-center"
                    // onClick={() => {
                    //   setIsShowAccount(true);
                    // }}
                    onClick={() => {
                      setIsActive(false);
                      navigate(UrlInternal.MYPAGE);
                    }}
                  >
                    My page
                  </div>
                  <div
                    className="text-[16px] font-[600] cursor-pointer hover:opacity-[0.7] text-center rounded-[48px] border-solid border-[#fff] border-[1px] py-2.5 px-5"
                    onClick={() => dispatch(handleSignOut())}
                  >
                    Logout
                  </div>
                </div>
              ) : (
                <div className="">
                  <div
                    className="text-[16px] font-[600] bg-[#B0F127] py-2.5 px-5 rounded-[48px] text-[#060606] cursor-pointer hover:opacity-80 mb-4 text-center"
                    onClick={() => navigate(UrlInternal.SIGNUP)}
                  >
                    Register -{" "}
                    <span className="text-[14px] font-[400] opacity-[0.6]">
                      It’s free
                    </span>
                  </div>
                  <div
                    className="text-[16px] font-[600] cursor-pointer hover:opacity-[0.7] text-center rounded-[48px] border-solid border-[#fff] border-[1px] py-2.5 px-5"
                    onClick={() => navigate(UrlInternal.LOGIN)}
                  >
                    Login
                  </div>
                </div>
              )}
              <MyAccount
                onClose={() => setIsShowAccount(false)}
                onReplace={() => {
                  setIsShowAccount(false);
                  setIsActive(false);
                }}
                onDeposit={() => setIsDeposit(true)}
                onWithdraw={() => setIsWithdraw(true)}
                isVisible={isShowAccount}
              />
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default Header;
