import { useEffect } from "react";
import StandsOut from "../Home/components/StandsOut";
import Banner from "./Banner";
import Benefits from "./Benefits";
import Different from "./Different";
import Milestones from "./Milestones";
import OurMission from "./OurMission";
import Revenue from "./Revenue";
import YourCrypto from "./YourCrypto";

function About() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Banner />
      <OurMission />
      <Different />
      <Benefits />
      <div className="md:mt-[-90px] mt-[-40px] md:mb-[-30px] mb-[15px]">
        <StandsOut />
      </div>
      <Revenue />
      <Milestones />
      <YourCrypto />
    </div>
  );
}

export default About;
