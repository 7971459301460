import { UrlInternal } from "@/constant/endpointRoute";
import { statusStaking } from "@/constant/statusStaking";
import { dataUserSelect, isAuthSelect } from "@/state/authSlice";
import { useAppSelector } from "@/state/hooks";
import moment from "moment";
import { useNavigate } from "react-router-dom";

interface Props {
  item: any;
  listItem: any;
  setIsStaking: (data: any) => void;
}
function ButtonStatusStaking({ item, listItem, setIsStaking }: Props) {
  const dataUser = useAppSelector(dataUserSelect);
  const isAuth = useAppSelector(isAuthSelect);
  const navigate = useNavigate();

  if (dataUser?.membershipLevel < item?.limitMembershipLevel) {
    return (
      <button
        className="sm:w-[129px] w-full text-[14px] text-[#060606] font-semibold border-0 cursor-pointer hover:opacity-70 bg-[#B0F127] h-[32px] rounded-full disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
                disabled:cursor-not-allowed"
        onClick={
          () =>
            !isAuth
              ? navigate(UrlInternal.LOGIN)
              : setIsStaking({
                  open: true,
                  data: {
                    dataClick: item,
                    listProduct: listItem,
                    statusStaking: statusStaking.START_EARNING,
                  },
                })
          // : navigate(UrlInternal.MEMBERSHIP_NFT)
        }
      >
        Upgrade
      </button>
    );
  }
  const stakingEndTime = new Date(item?.userStakingEndTime);
  const timeNow = new Date();
  if (
    item?.stakingStatus === 2 ||
    (item?.stakingStatus === 1 && stakingEndTime.getTime() <= timeNow.getTime())
  )
    return (
      <button
        onClick={() =>
          !isAuth
            ? navigate(UrlInternal.LOGIN)
            : setIsStaking({
                open: true,
                data: {
                  dataClick: item,
                  listProduct: listItem,
                  statusStaking: statusStaking.GET_PROFIT,
                },
              })
        }
        className="sm:w-[129px] w-full text-[14px] font-semibold border border-solid border-[#fff] text-[#fff] cursor-pointer hover:opacity-70 bg-[transparent] h-[32px] px-5 rounded-full"
      >
        Get profit
      </button>
    );
  if (item?.stakingStatus === 1 && stakingEndTime.getTime() > timeNow.getTime())
    return (
      <button
        onClick={() =>
          !isAuth
            ? navigate(UrlInternal.LOGIN)
            : setIsStaking({
                open: true,
                data: {
                  dataClick: item,
                  listProduct: listItem,
                  statusStaking: statusStaking.EARNING,
                },
              })
        }
        className="sm:w-[129px] w-full text-[14px] font-semibold border border-solid border-[#B0F127] text-[#B0F127] cursor-pointer hover:opacity-70 bg-[transparent] h-[32px] px-5 rounded-full"
      >
        Earning
      </button>
    );
  return (
    <>
      {moment(item?.startAt).isBefore(moment()) ? (
        <button
          className="sm:w-[129px] w-full text-[14px] text-[#060606] font-semibold border-0 cursor-pointer hover:opacity-70 bg-[#B0F127] h-[32px] rounded-full disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
                disabled:cursor-not-allowed"
          onClick={() =>
            !isAuth
              ? navigate(UrlInternal.LOGIN)
              : setIsStaking({
                  open: true,
                  data: {
                    dataClick: item,
                    listProduct: listItem,
                    statusStaking: statusStaking.START_EARNING,
                  },
                })
          }
          disabled={item?.limitTotalStaking === item?.totalStaking}
        >
          Start earning
        </button>
      ) : (
        <button
          className="sm:w-[129px] w-full text-[14px] text-[#060606] font-semibold border-0 cursor-pointer hover:opacity-70 bg-[#fff] h-[32px] rounded-full"
          onClick={() =>
            !isAuth
              ? navigate(UrlInternal.LOGIN)
              : setIsStaking({
                  open: true,
                  data: {
                    dataClick: item,
                    listProduct: listItem,
                    statusStaking: statusStaking.START_EARNING,
                  },
                })
          }
        >
          Coming soon
        </button>
      )}
    </>
  );
}

export default ButtonStatusStaking;
