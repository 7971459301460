import FilterIcon from "@/assets/images/Filter.svg";
import Checkbox from "@/components/Checkbox";
import SelectCustom from "@/components/SelectCustom";
import { coinsSelect } from "@/state/coinSlice";
import {
  filtersSelect,
  setAssetType,
  setCanStaking,
  setDurtions,
  setRank,
  setSearch,
} from "@/state/filterSlice";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { Select, SelectProps } from "antd";
import { debounce } from "lodash";
import { useMemo, useState } from "react";
import FilterMobile from "./FilterMobile";

const DurationsOption: SelectProps["options"] = [
  {
    label: "All Durations",
    value: "",
  },
  {
    label: "7",
    value: "7",
  },
  {
    label: "14",
    value: "14",
  },
  {
    label: "21",
    value: "21",
  },
  {
    label: "30",
    value: "30",
  },
];

function Filter({ dataLevel }: any) {
  const dispatch = useAppDispatch();
  const dataListCoin = useAppSelector(coinsSelect);
  const isChecked = useAppSelector(filtersSelect);
  const [isFilter, setIsFilter] = useState(false);

  const handleSearch = useMemo(
    () =>
      debounce((e: any) => {
        const value = e.target.value;
        dispatch(setSearch(value));
      }, 1000),
    []
  );

  return (
    <div className="flex gap-4 items-center">
      {/* <div className="flex-1 md:flex-none md:min-w-[351px]">
        <CustomInput
          placeholder="Search coins"
          onChange={handleSearch}
          prefix={<SearchOutlined />}
        />
      </div> */}
      <div className="md:block hidden">
        <SelectCustom
          height={46}
          width={168}
          placeholder={"All Durations"}
          popupClassName="select-long"
          className="select-filter"
          onChange={(value) => {
            dispatch(setDurtions(value));
          }}
        >
          {DurationsOption?.map((dt, i) => (
            <Select.Option key={i} value={dt.value}>
              <div className="flex justify-center items-center gap-[10px] option-select">
                <div className="text-[#fff]">{dt.label}</div>
              </div>
            </Select.Option>
          ))}
        </SelectCustom>
      </div>

      <div className="md:block hidden">
        <SelectCustom
          height={46}
          width={168}
          placeholder={"All Coins"}
          popupClassName="select-long"
          className="select-filter"
          onChange={(value) => {
            dispatch(setAssetType(value));
          }}
        >
          <Select.Option value="">
            <div className="flex justify-center items-center gap-[10px] option-select">
              <div className="text-[#fff]">All Coins</div>
            </div>
          </Select.Option>
          {dataListCoin?.map((dt: any, i: any) => (
            <Select.Option key={i} value={dt.assetSymbol}>
              <div className="flex justify-center items-center gap-[10px] option-select">
                <div className="text-[#fff]">{dt.assetSymbol}</div>
              </div>
            </Select.Option>
          ))}
        </SelectCustom>
      </div>

      <div className="md:block hidden">
        <SelectCustom
          height={46}
          width={202}
          placeholder={"Membership Rank"}
          popupClassName="select-long"
          className="select-filter"
          onChange={(value) => {
            dispatch(setRank(value));
          }}
          dropdownMatchSelectWidth={false}
        >
          <Select.Option value="">
            <div className="flex justify-center items-center gap-[10px] option-select">
              <div className="text-[#fff]">All Membership</div>
            </div>
          </Select.Option>
          {dataLevel?.map((dt: any, i: any) => (
            <Select.Option key={i} value={dt.grade}>
              <div className="flex justify-center items-center gap-[10px] option-select">
                <div className="text-[#fff]">{dt.gradeName}</div>
              </div>
            </Select.Option>
          ))}
        </SelectCustom>
      </div>
      <div className="md:block hidden ml-auto">
        <Checkbox
          defaultValue={isChecked.canStaking}
          content="Earnable Products"
          onClick={(value: any) => {
            const valueFilter: any = value ? 1 : null;
            dispatch(setCanStaking(valueFilter));
          }}
        />
      </div>

      <div
        className="md:hidden cursor-pointer hover:opacity-80"
        onClick={() => setIsFilter(!isFilter)}
      >
        <img src={FilterIcon} />
      </div>
      {isFilter && (
        <FilterMobile
          dataLevel={dataLevel}
          dataDurations={DurationsOption}
          onClose={() => {
            setIsFilter(false);
          }}
        />
      )}
    </div>
  );
}

export default Filter;
