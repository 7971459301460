import ImgArrowLeft from "@/assets/img/arrow-left.png";
import PaginationCustom from "@/components/PaginationCustom";
import { PaginationProps } from "antd";
import { useEffect, useState } from "react";
import TableProfit from "./TableProfit";
import TableEarn from "./TableEarn";
import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UrlInternal } from "@/constant/endpointRoute";
import NotFound from "@/components/NotFound";
import TableReferralProfit from "./TableReferralProfit";
import { useAppDispatch, useAppSelector } from "@/state/hooks";
import { getDataWithdrawList, withdrawSelect } from "@/state/withdrawSlice";
import TableDeposit from "./TableDeposit";
import TableWithdraw from "./TableWithdraw";

import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { FreeMode } from "swiper/modules";
import { dataUserSelect } from "@/state/authSlice";
import TablePoints from "./TablePoints";

const dataValueTab = {
  profit: "PROFIT",
  earn: "EARN",
  referralProfitHeader: "REFERRAL_PROFIT_HEADER",
  referralProfitGeneral: "REFERRAL_PROFIT_GENERAL",
  deposit: "DEPOSIT",
  withdraw: "WITHDRAW",
  point: "POINT",
};
const dataTab = [
  {
    title: "Profit",
    value: dataValueTab.profit,
  },
  {
    title: "Earn",
    value: dataValueTab.earn,
  },
  {
    title: "Points",
    value: dataValueTab.point,
  },
  {
    title: "Referral profit (header)",
    value: dataValueTab.referralProfitHeader,
  },
  {
    title: "Referral profit (general)",
    value: dataValueTab.referralProfitGeneral,
  },
  {
    title: "Deposit",
    value: dataValueTab.deposit,
  },
  {
    title: "Withdrawal",
    value: dataValueTab.withdraw,
  },
];

function History() {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;
  const tab = searchParams.get("tab") || dataValueTab.profit;
  const pageSize = 10;
  const [dataPage, setDataPage] = useState<any>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dataUser = useAppSelector(dataUserSelect);
  const dataWithdraw = useAppSelector(withdrawSelect);
  const dataSource =
    tab === dataValueTab.withdraw ? dataWithdraw || [] : dataPage;

  console.log("dataSource", dataSource);

  const dataTabFilter = dataUser?.isAgency
    ? dataTab
    : dataTab.filter(
        (item) => item.value !== dataValueTab.referralProfitHeader
      );

  const onChange: PaginationProps["onChange"] = (page) => {
    searchParams.set("page", String(page));
    setSearchParams(searchParams);
  };

  const getData = async () => {
    try {
      if (tab === dataValueTab.earn || tab === dataValueTab.profit) {
        const resp: any = await sendApiRequest({
          ...apiConfig.earnProfit.getList,
          body: {
            page: Number(page),
            size: pageSize,
            querySearch: "",
            listUserStakingStatus: tab === dataValueTab.profit ? "ENDED" : null,
          },
        });
        if (resp.result) {
          setDataPage(resp.data);
        }
      }
      if (
        tab === dataValueTab.referralProfitHeader ||
        tab === dataValueTab.referralProfitGeneral
      ) {
        const resp: any = await sendApiRequest({
          ...apiConfig.referralProfit.getList,
          body: {
            page: Number(page) || 1,
            size: pageSize,
            querySearch: "",
            rewardType:
              tab === dataValueTab.referralProfitHeader
                ? "FROM_STAKING_AGENCY"
                : "FROM_STAKING",
          },
        });
        if (resp.result) {
          setDataPage(resp.data);
        }
      }
      if (tab === dataValueTab.deposit) {
        const resp: any = await sendApiRequest({
          ...apiConfig.deposit.getListDepositHistory,
          body: {
            page: Number(page),
            size: pageSize,
            query: "",
            startTime: "",
            endTime: "",
          },
        });
        if (resp?.datas?.length > 0) {
          setDataPage(resp);
        }
      }
      if (tab === dataValueTab.point) {
        const resp: any = await sendApiRequest({
          ...apiConfig.point.getList,
          body: {
            page: Number(page),
            size: pageSize,
            query: "",
            startTime: "",
            endTime: "",
          },
        });
        if (resp?.result) {
          setDataPage(resp?.data);
        }
      }

      if (tab === dataValueTab.withdraw) {
        await dispatch(getDataWithdrawList(Number(page), pageSize));
      }
    } catch (error) {
      console.log();
      setDataPage({});
    }
  };

  useEffect(() => {
    getData();
  }, [page, tab]);

  return (
    <div className="px-[16px]">
      <div
        className={`bg-gradient-2 sm:px-[49px] sm:pt-[54px] pb-[80px] overflow-y-auto sm:rounded-[50px] sm:border sm:border-[#FFFFFF40] sm:border-solid max-w-[1000px] w-full mx-auto sm:mt-[47px] mb-[55px]`}
      >
        <div className="flex items-center justify-between sm:mt-0 mt-[16.5px] mb-[33px]">
          <div
            className="min-w-[24px] cursor-pointer"
            // onClick={() => navigate(UrlInternal.HOME)}
            onClick={() => {
              navigate(UrlInternal.HOME, {
                state: {
                  isBack: true,
                },
              });
            }}
          >
            <img src={ImgArrowLeft} alt="img" />
          </div>
          <div className="sm:text-[32px] text-[20px] font-montserrat700 text-center">
            History
          </div>
          <div className="min-w-[24px]"></div>
        </div>

        {/* <div className="flex overflow-x-auto"> */}
        <Swiper
          // ref={swiperRef}
          // className="swiper-category-brand"
          freeMode={true}
          slidesPerView={"auto"}
          spaceBetween={0}
          // spaceBetween={8}
          pagination={{
            clickable: false,
          }}
          modules={[FreeMode]}
          initialSlide={dataTabFilter.findIndex((item) => item.value === tab)}
        >
          {dataTabFilter.map((dt, i) => (
            <SwiperSlide className="w-auto" key={dt.value}>
              <div
                key={i}
                className={`pb-[10px] border-0 border-b-[3px] border-solid text-[18px] px-[20px] w-full whitespace-nowrap text-center cursor-pointer ${
                  tab === dt.value
                    ? "text-[#fff] border-[#B0F127]"
                    : "text-[#888888] border-[#222222]"
                }`}
                onClick={() => {
                  searchParams.set("tab", dt.value);
                  setSearchParams(searchParams);
                }}
              >
                {dt.title}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {/* </div> */}
        {dataSource?.datas?.length > 0 ? (
          <>
            {tab === dataValueTab.profit && (
              <TableProfit data={dataSource?.datas} />
            )}
            {tab === dataValueTab.earn && (
              <TableEarn data={dataSource?.datas} />
            )}
            {tab === dataValueTab.point && (
              <TablePoints data={dataSource?.datas} />
            )}
            {(tab === dataValueTab.referralProfitHeader ||
              tab === dataValueTab.referralProfitGeneral) && (
              <TableReferralProfit data={dataSource?.datas} />
            )}

            {tab === dataValueTab.deposit && (
              <TableDeposit data={dataSource.datas} />
            )}
            {tab === dataValueTab.withdraw && (
              <TableWithdraw data={dataSource.datas} />
            )}

            <PaginationCustom
              current={Number(page)}
              onChange={onChange}
              total={dataSource?.pageable?.totalElement || 0}
              pageSize={pageSize}
            />
          </>
        ) : (
          <NotFound />
        )}
      </div>
    </div>
  );
}

export default History;
