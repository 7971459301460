import ImgTop from "@/assets/img/top.png";
import ImgBottom from "@/assets/img/bottom.png";
import moment from "moment";
import ImgUsdt from "@/assets/img/usdt.png";
import { useState } from "react";
import { formatPrice } from "@/utils/formatPrice";
import { useAppSelector } from "@/state/hooks";
import { coinsSelect } from "@/state/coinSlice";

interface Props {
  dt?: any;
}

function Collapse({ dt }: Props) {
  const [isShow, setIsShow] = useState(true);
  const coins = useAppSelector(coinsSelect);

  const handleGetIconCoin = (assetSymbol: string) => {
    const coin = coins.find((coin: any) => coin.assetSymbol === assetSymbol);

    return coin?.assetIcon;
  };

  return (
    <div className="py-[12px] border-0 border-solid border-b border-[#2F2F2F] px-[16px] ">
      <div
        className="flex justify-between cursor-pointer items-center"
        onClick={() => setIsShow(!isShow)}
      >
        <div className="leading-0 max-w-[100px] text-[14px]">
          {moment(dt?.createdAt).format("YYYY-MM-DD HH:mm")}
        </div>
        <div className="flex items-center gap-[10px]">
          <img
            src={handleGetIconCoin(dt?.stakingInfo?.asset_type)}
            alt="usdt"
            className="max-w-[28px] w-full aspect-square"
          />
          <div className="text-[16px] text-[#EAECEF] font-montserrat500">
            {dt?.stakingInfo?.asset_type}
          </div>
        </div>
        <div className="text-[18px] font-montserrat700">2.54</div>
        {/* <div className="flex items-center">
          <img src={!isShow ? ImgBottom : ImgTop} alt="top" />
        </div> */}
      </div>
      {isShow && (
        <div className="pb-[14px] pt-[12px] mt-[10px] border-0 border-solid border-t border-[#2F2F2F]">
          <div className="flex">
            <div className="flex-1">
              <div className="text-[12px] text-[#C0C0C0] pb-[4px]">
                Product Name
              </div>
              <div className="text-[16px]">{dt?.stakingInfo?.product_name}</div>
            </div>
            <div className="flex-1">
              <div className="text-[12px] text-[#C0C0C0] pb-[4px]">
                Duration
              </div>
              <div className="text-[16px]">
                {dt?.stakingInfo?.days_duration} days
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Collapse;
