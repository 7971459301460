import Img from "@/assets/images/about/mission.svg";
import Img1 from "@/assets/images/about/mission1.svg";
import Img2 from "@/assets/images/about/mission2.svg";
import Img3 from "@/assets/images/about/mission3.svg";

function OurMission() {
  return (
    <div className="bg-[#B0F127]">
      <div className="lg:py-[80px] py-10 leading-none max-w-[1728px] px-4 mx-auto w-full">
        <div className="text-center lg:text-[54px] text-[32px] font-montserrat600 text-[#000]">
          Our Mission
        </div>
        <div className="text-center text-[20px] text-[#000] pt-[10px] pb-4">
          To democratize yield generation by making it
        </div>
        <div className="grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 sm:gap-[15px] gap-[50px]">
          <div className="flex-1 text-center">
            <img src={Img} alt="img" className="mx-auto" />
            <div className="text-[#000] lg:text-[32px] text-[26px] font-montserrat600 lg:pt-[26px] pt-[10px] lg:pb-[14px] pb-[8px]">
              Accessible
            </div>
            <div className="text-[#000] lg:text-[24px] text-[20px] sm:px-[10px]">
              No matter who you are or where you live
            </div>
          </div>
          <div className="flex-1 text-center">
            <img src={Img1} alt="img" className="mx-auto" />
            <div className="text-[#000] lg:text-[32px] text-[26px] font-montserrat600 lg:pt-[26px] pt-[10px] lg:pb-[14px] pb-[8px]">
              Secure
            </div>
            <div className="text-[#000] lg:text-[24px] text-[20px] sm:px-[30px]">
              With real-time risk monitoring and smart contract protections
            </div>
          </div>
          <div className="flex-1 text-center">
            <img src={Img2} alt="img" className="mx-auto" />
            <div className="text-[#000] lg:text-[32px] text-[26px] font-montserrat600 lg:pt-[26px] pt-[10px] lg:pb-[14px] pb-[8px]">
              Automated
            </div>
            <div className="text-[#000] lg:text-[24px] text-[20px] sm:px-[10px]">
              Let AI do the work while you reap the rewards
            </div>
          </div>
          <div className="flex-1 text-center">
            <img src={Img3} alt="img" className="mx-auto" />
            <div className="text-[#000] lg:text-[32px] text-[26px] font-montserrat600 lg:pt-[26px] pt-[10px] lg:pb-[14px] pb-[8px]">
              Available to all
            </div>
            <div className="text-[#000] lg:text-[24px] text-[20px] sm:px-[25px]">
              We envision a world where the most powerful financial tools are
              not exclusive — but available to all
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurMission;
