import { statusStaking } from "@/constant/statusStaking";

export const handleCheckStatusStaking = (item: any) => {
  const stakingEndTime = new Date(item?.userStakingEndTime);
  const timeNow = new Date();

  if (
    item?.stakingStatus === 2 ||
    (item?.stakingStatus === 1 && stakingEndTime.getTime() <= timeNow.getTime())
  ) {
    return statusStaking.GET_PROFIT;
  }
  if (
    item?.stakingStatus === 1 &&
    stakingEndTime.getTime() > timeNow.getTime()
  ) {
    return statusStaking.EARNING;
  }

  return statusStaking.START_EARNING;
};
