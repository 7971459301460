import Img from "@/assets/images/about/different.svg";

function Different() {
  return (
    <div className="max-w-[1313px] px-4 mx-auto w-full md:mt-[200px] mt-[50px] md:mb-[150px] mb-[60px]">
      <div className="lg:text-[54px] text-[32px] font-montserrat600 text-center xl:pb-[60px] pb-[30px]">
        What Makes Defylo{" "}
        <span className="text-[#B0F127] mb-[60px]">Different?</span>
      </div>

      <div className="border border-solid border-[#2F2F2F] rounded-[24px]">
        <div className="flex justify-center items-center py-9 gap-[55px] md:flex-row flex-col">
          <img src={Img} alt="img" />
          <div className="font-montserrat600 text-[26px] max-w-[470px] md:text-left text-center">
            AI-Optimized Yield Strategies <br /> Real-time detection of global
            price inefficiencies. Algorithmic allocation of capital for maximum
            returns
          </div>
        </div>
        <div className="border-0 border-t border-solid border-[#2F2F2F] flex md:items-center md:flex-row flex-col">
          <div className="flex-1 lg:text-[24px] text-[20px] text-center">
            <div className="lg:px-10 px-[11px] lg:py-[63px] py-10">
              Enterprise-Grade Security
              <div className="font-montserrat600 text-[#B0F127]">
                24/7 protocol monitoring
              </div>
              Audited smart contracts and multi-layer protection
            </div>
          </div>
          <div className="flex-1 lg:text-[24px] text-[20px] text-center border-0 md:border-l md:border-r border-t border-b border-solid border-[#2F2F2F]">
            <div className="lg:px-10 px-[11px] lg:py-[63px] py-10">
              <div className="font-montserrat600 text-[#B0F127]">
                Global Arbitrage Engine
              </div>
              Integrated with leading centralized and decentralized exchanges
              <div>Cross-border execution optimized by AI</div>
            </div>
          </div>
          <div className="flex-1 lg:text-[24px] text-[20px] text-center ">
            <div className="lg:px-0 px-[11px] lg:py-[63px] py-10">
              <div>Tailored Yield Experiences</div> From entry-level users to
              elite NFT members{" "}
              <span className="font-montserrat600 text-[#B0F127]">
                Dynamic rewards
              </span>{" "}
              based on activity, tier, and participation
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Different;
