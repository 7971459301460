import { useRef, useState } from "react";
import "swiper/css/pagination";
import { EffectCoverflow, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperProps, SwiperSlide } from "swiper/react";
import NextIcon from "@/assets/images/home/CaretRight.png";
import PrevIcon from "@/assets/images/home/CaretLeft.png";
import TagRank from "@/components/TagRank";
import Progress from "@/components/Progress";
import moment from "moment";
import { useAppSelector } from "@/state/hooks";
import { dataUserSelect, isAuthSelect } from "@/state/authSlice";
import { useNavigate } from "react-router-dom";
import { UrlInternal } from "@/constant/endpointRoute";
import StartEarning from "@/components/StartEarning";
import Earning from "@/components/Earning";
import GetProfit from "@/components/GetProfit";
import { formatPrice } from "@/utils/formatPrice";
import { coinsSelect } from "@/state/coinSlice";
import ModalStaking from "@/components/ModalStaking";
import { statusStaking } from "@/constant/statusStaking";

type Props = {
  data: any;
  getListProducts?: () => void;
};

function HightProduct({ data, getListProducts }: Props & SwiperProps) {
  const swiperRef: any = useRef();
  const navigate = useNavigate();
  const dataUser = useAppSelector(dataUserSelect);
  const isAuth = useAppSelector(isAuthSelect);
  const dataListCoin = useAppSelector(coinsSelect);

  // const [isStartEarning, setIsStartEarning] = useState({
  //   open: false,
  //   data: {},
  // });
  // const [isGetProfit, setIsGetProfit] = useState({
  //   open: false,
  //   data: {},
  // });
  // const [isEarning, setIsEarning] = useState({
  //   open: false,
  //   data: {},
  // });
  const [isStaking, setIsStaking] = useState({
    open: false,
    data: {},
  });

  // const getListStartEarning = (dataProduct: any) => {
  //   // console.log("data dataProduct", data);
  //   // console.log("dataProduct", dataProduct);

  //   const stakingEndTime = new Date(dataProduct?.userStakingEndTime);
  //   const timeNow = new Date();

  //   const listStartEarning = data?.filter(
  //     (item: any) =>
  //       dataUser?.membershipLevel < item?.limitMembershipLevel ||
  //       (!(
  //         item?.stakingStatus === 2 ||
  //         (item?.stakingStatus === 1 &&
  //           stakingEndTime.getTime() <= timeNow.getTime())
  //       ) &&
  //         moment(item?.startAt).isBefore(moment()))
  //   );

  //   setIsStartEarning({
  //     open: true,
  //     data: {
  //       ...dataProduct,
  //       listStartEarning: listStartEarning,
  //     },
  //   });
  // };

  const renderButton = (item: any, listItem?: any) => {
    if (dataUser?.membershipLevel < item?.limitMembershipLevel) {
      return (
        <button
          className="sm:w-[165px] w-full text-[14px] text-[#060606] font-semibold border-0 cursor-pointer hover:opacity-70 bg-[#B0F127] h-[32px] rounded-full disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
                disabled:cursor-not-allowed"
          onClick={
            () =>
              !isAuth
                ? navigate(UrlInternal.LOGIN)
                : setIsStaking({
                    open: true,
                    data: {
                      dataClick: item,
                      listProduct: listItem,
                      statusStaking: statusStaking.START_EARNING,
                    },
                  })
            // : navigate(UrlInternal.MEMBERSHIP_NFT)
          }
        >
          Upgrade
        </button>
      );
    }
    const stakingEndTime = new Date(item?.userStakingEndTime);
    const timeNow = new Date();
    if (
      item?.stakingStatus === 2 ||
      (item?.stakingStatus === 1 &&
        stakingEndTime.getTime() <= timeNow.getTime())
    )
      return (
        <button
          onClick={() =>
            !isAuth
              ? navigate(UrlInternal.LOGIN)
              : setIsStaking({
                  open: true,
                  data: {
                    dataClick: item,
                    listProduct: listItem,
                    statusStaking: statusStaking.GET_PROFIT,
                  },
                })
          }
          className="sm:w-[165px] w-full text-[14px] font-semibold border border-solid border-[#fff] text-[#fff] cursor-pointer hover:opacity-70 bg-[transparent] h-[32px] px-5 rounded-full"
        >
          Get profit
        </button>
      );
    if (
      item?.stakingStatus === 1 &&
      stakingEndTime.getTime() > timeNow.getTime()
    )
      return (
        <button
          onClick={() =>
            !isAuth
              ? navigate(UrlInternal.LOGIN)
              : setIsStaking({
                  open: true,
                  data: {
                    dataClick: item,
                    listProduct: listItem,
                    statusStaking: statusStaking.EARNING,
                  },
                })
          }
          className="sm:w-[165px] w-full text-[14px] font-semibold border border-solid border-[#B0F127] text-[#B0F127] cursor-pointer hover:opacity-70 bg-[transparent] h-[32px] px-5 rounded-full"
        >
          Earning
        </button>
      );
    return (
      <>
        {moment(item?.startAt).isBefore(moment()) ? (
          <button
            className="sm:w-[165px] w-full text-[14px] text-[#060606] font-semibold border-0 cursor-pointer hover:opacity-70 bg-[#B0F127] h-[32px] rounded-full disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
                disabled:cursor-not-allowed"
            onClick={() =>
              !isAuth
                ? navigate(UrlInternal.LOGIN)
                : setIsStaking({
                    open: true,
                    data: {
                      dataClick: item,
                      listProduct: listItem,
                      statusStaking: statusStaking.START_EARNING,
                    },
                  })
            }
            disabled={item?.limitTotalStaking === item?.totalStaking}
          >
            Start earning
          </button>
        ) : (
          <button
            className="sm:w-[165px] w-full text-[14px] text-[#060606] font-semibold border-0 cursor-pointer hover:opacity-70 bg-[#fff] h-[32px] rounded-full"
            onClick={() =>
              !isAuth
                ? navigate(UrlInternal.LOGIN)
                : setIsStaking({
                    open: true,
                    data: {
                      dataClick: item,
                      listProduct: listItem,
                      statusStaking: statusStaking.START_EARNING,
                    },
                  })
            }
          >
            Coming soon
          </button>
        )}
      </>
    );
  };

  const handlePerView = () => {
    if (data?.length < 4) {
      return data?.length > 1 ? data?.length : 2;
    } else {
      return 4;
    }
  };

  return (
    <>
      <ModalStaking
        isVisible={isStaking.open}
        setCancel={() => setIsStaking({ open: false, data: {} })}
        data={isStaking.data}
        onSuccess={() => getListProducts?.()}
      />
      {/* <StartEarning
        isVisible={isStartEarning.open}
        setCancel={() => setIsStartEarning({ open: false, data: {} })}
        data={isStartEarning.data}
        onSuccess={() => getListProducts?.()}
      />
      <Earning
        isVisible={isEarning.open}
        setCancel={() => setIsEarning({ open: false, data: {} })}
        data={isEarning.data}
        onSuccess={() => getListProducts?.()}
      />
      <GetProfit
        isVisible={isGetProfit.open}
        setCancel={() => setIsGetProfit({ open: false, data: {} })}
        data={isGetProfit.data}
        onSuccess={() => getListProducts?.()}
      /> */}

      <div className="relative">
        <Swiper
          className="swiper-hightlight py-[40px] mt-[-40px]"
          // centeredSlides
          // effect={"coverflow"}
          // slideToClickedSlide
          pagination={{
            clickable: true,
          }}
          // pagination={true}
          modules={[Pagination]}
          // modules={[EffectCoverflow, Pagination]}
          // rewind
          // coverflowEffect={{
          //   rotate: 0,
          //   stretch: 0,
          //   modifier: 4,
          //   slideShadows: true,
          // }}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          breakpoints={{
            320: {
              slidesPerView: data?.length === 1 ? 1 : 1.2,
              spaceBetween: 16,
            },
            1024: {
              slidesPerView: handlePerView(),
              spaceBetween: 24,
              // slidesPerGroupSkip: 4,
            },
          }}
        >
          {data.map((item: any, key: number) => (
            <SwiperSlide key={key}>
              <div className="bg-gradient-hightlight p-6 rounded-[18px] border border-solid border-[#FFFFFF40] flex flex-col h-full">
                <div className="flex items-center justify-between ">
                  <div className="flex items-center gap-[15px]">
                    <img
                      src={
                        dataListCoin?.find(
                          (v: any) => v?.assetSymbol === item?.assetType
                        )?.assetIcon
                      }
                      alt="coin"
                      className="w-[37px] aspect-square"
                    />
                    <div className="text-[#EAECEF] font-montserrat700 text-[24px]">
                      {item?.assetType}
                    </div>
                  </div>
                  {item?.limitMembershipLevel > 0 && (
                    <div className="mt-[6px]">
                      <TagRank
                        level={item?.limitMembershipLevel}
                        limitMembershipGradeCondition={
                          item?.limitMembershipGradeCondition
                        }
                      />
                    </div>
                  )}
                </div>

                <div className="text-[#EAECEF] font-montserrat700 text-[18px] my-[23.5px] flex-1 flex items-center">
                  {item?.productName}
                </div>

                <div className="mb-[16px]">
                  <div className="flex justify-between items-center font-montserrat500 text-[#898989] text-[12px]">
                    <div>Duration</div>
                    <div>APR</div>
                  </div>
                  <div className="flex justify-between items-center font-montserrat700 text-[#EAECEF] text-[16px]">
                    <div>{item?.daysDuration} Days</div>
                    <div>{formatPrice(item?.apr * 100)}%</div>
                  </div>
                </div>

                <Progress
                  value={item?.totalStaking}
                  valueTarget={item?.limitTotalStaking}
                  isComingSoon={moment(item?.startAt).isAfter(moment())}
                  content="text-right"
                />

                <div className="mt-6 flex justify-center">
                  {renderButton(item, data)}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

        {data?.length > 4 && (
          <>
            <div
              onClick={() => swiperRef.current?.slidePrev()}
              className="absolute top-1/2 left-[-44px] -translate-y-1/2 cursor-pointer"
            >
              <img src={PrevIcon} alt="prev" />
            </div>
            <div
              onClick={() => swiperRef.current?.slideNext()}
              className="absolute top-1/2 right-[-44px] -translate-y-1/2 cursor-pointer"
            >
              <img src={NextIcon} alt="next" />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default HightProduct;
