import Img from "@/assets/images/about/your_crypto.svg";
import { UrlInternal } from "@/constant/endpointRoute";
import { useNavigate } from "react-router-dom";
function YourCrypto() {
  const navigate = useNavigate();

  return (
    <div className="bg-[#B0F127] py-[76px] leading-none xl:mt-[229px] mt-[50px] relative">
      <div className="max-w-[1144px] px-4 mx-auto w-full">
        <div className="max-w-[600px] lg:mx-0 mx-auto lg:mb-0 mb-[51px]">
          <div className="text-[#141414] text-[42px] font-BlackOpsOne mb-[25px] text-center uppercase">
            Let your crypto work for you — powered by AI, optimized by
            <span className="text-[#000000]">Defylo.</span>
          </div>
          <div
            onClick={() =>
              navigate({
                pathname: UrlInternal.HOME,
                search: `?menu=Earn`,
              })
            }
            className="max-w-[360px] cursor-pointer w-full mx-auto text-[#B0F127] md:text-[32px] text-[28px] font-montserrat700 text-center bg-[#060606] h-[75px] leading-[75px] rounded-[48px]"
          >
            Start Staking Now
          </div>
        </div>
      </div>
      <img
        src={Img}
        alt="img"
        className="lg:absolute right-0 bottom-0 2xl:max-w-max lg:max-w-[600px] lg:pl-0 pl-4"
      />
    </div>
  );
}

export default YourCrypto;
