import ImgLine from "@/assets/images/about/line.svg";
import ImgRevenue from "@/assets/images/about/revenue.svg";
function Revenue() {
  return (
    <div className="max-w-[1313px] px-4 w-full mx-auto leading-none md:mb-[166px] mb-[100px]">
      <div className="lg:text-[54px] text-[32px] font-montserrat600 text-center">
        How We Generate Revenue
      </div>
      <div className="text-[#B0F127] font-montserrat700 text-[20px] mt-[10px] xl:mb-[106px] mb-[30px] text-center">
        Global Arbitrage. Automated by AI.
      </div>
      <img src={ImgLine} alt="line" className="mx-auto sm:block hidden" />
      <div className="grid md:grid-cols-4 grid-cols-1 md:gap-[30px] gap-4 items-center mt-[28px] leading-[34px]">
        <div className="bg-revenue flex items-center justify-center rounded-[45px] xl:h-[402px] h-full py-5">
          <div className="xl:text-[24px] md:text-[14px] text-[20px] font-montserrat500 xl:px-8 px-[26px] md:text-left text-center">
            The price of USDT varies between countries due to demand,
            regulation, and liquidity conditions.
          </div>
        </div>
        <div className="bg-revenue flex items-center justify-center rounded-[45px] xl:h-[402px] h-full py-5">
          <div className="xl:text-[24px] md:text-[14px] text-[20px] font-montserrat500 xl:px-7 px-5 md:text-left text-center">
            Defylo’s AI continuously monitors these price gaps and executes
            automated trades: <br />+ Buying USDT in low-cost markets <br />+
            Selling it where premiums are higher
          </div>
        </div>
        <div className="bg-revenue flex items-center justify-center rounded-[45px] xl:h-[402px] h-full py-5">
          <div className="xl:text-[24px] md:text-[14px] text-[20px] font-montserrat500 xl:px-8 px-[28.5px] md:text-left text-center">
            After deducting a small platform fee, profits are distributed to
            users automatically — no manual trading, no guesswork.
          </div>
        </div>
        <div className=" bg-[#B0F127] rounded-[45px] xl:h-[402px] h-full py-5 flex flex-col items-center justify-center">
          <div className="xl:px-9 px-[58.5px] flex flex-col items-center justify-center">
            <div className="text-[#141414] font-montserrat700 xl:text-[32px] md:text-[18px] text-[30px] mb-[25px] text-center">
              You earn — <br />
              from global <br />
              inefficiencies.
              <br /> Intelligently.
            </div>
            <img src={ImgRevenue} alt="revenue" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Revenue;
