import React, { useEffect, useState } from "react";
import CaretDown from "@/assets/images/CaretDown.svg";
import ImgUsdt from "@/assets/img/usdt.png";
import { styled } from "styled-components";
import { useAppSelector } from "@/state/hooks";
import { coinsSelect } from "@/state/coinSlice";
import sendApiRequest from "@/axios/requestApi";
import { apiConfig } from "@/constant/apiConfig";
import { dataUserSelect, isAuthSelect, tokenSelect } from "@/state/authSlice";
import { useNavigate } from "react-router-dom";
import { UrlInternal } from "@/constant/endpointRoute";
import StartEarning from "@/components/StartEarning";
import Earning from "@/components/Earning";
import GetProfit from "@/components/GetProfit";
import { filtersSelect } from "@/state/filterSlice";
import moment from "moment";
import Progress from "@/components/Progress";
import CheckRank from "@/components/CheckRank";
import NotFound from "@/components/NotFound";
import { formatPrice } from "@/utils/formatPrice";
import TagRank from "@/components/TagRank";
import RankSilver from "@/assets/images/mypage/silver.png";
import RankGold from "@/assets/images/mypage/gold.png";
import RankDiamond from "@/assets/images/mypage/diamond.png";
import RankBlack from "@/assets/images/mypage/black.png";
import ModalStaking from "@/components/ModalStaking";
import { statusStaking } from "@/constant/statusStaking";
import { handleCheckStatusStaking } from "@/utils/handleCheckStatusStaking";
import ButtonStatusStaking from "@/components/ButtonStatusStaking";

const dataRank = [RankSilver, RankGold, RankDiamond, RankBlack];

const Wrapper = styled.div`
  width: 100%;
  /* background-color: red; */
`;

// const data = [
//   { id: 1, name: "USDC", icon: ImgUsdt, apr: 28, product: "7 days earn" },
//   { id: 2, name: "USDT", icon: ImgUsdt, apr: 32, product: "7 days earn" },
//   { id: 3, name: "FDUSD", icon: ImgUsdt, apr: 25, product: "7 days earn" },
//   { id: 4, name: "BNB", icon: ImgUsdt, apr: 25, product: "7 days earn" },
// ];

interface Props {
  listGrade: any;
}

const ListCoin: React.FC<Props> = ({ listGrade }) => {
  const navigate = useNavigate();
  const dataListCoin = useAppSelector(coinsSelect);
  const dataFilters = useAppSelector(filtersSelect);
  const dataUser = useAppSelector(dataUserSelect);
  const isAuth = useAppSelector(isAuthSelect);

  const [dataProducts, setDataProducts] = useState([]);
  const [tableParams] = useState({
    page: 1,
    size: 999,
  });
  const [totalElement, setTotalElement] = useState(null);

  // const [isStartEarning, setIsStartEarning] = useState({
  //   open: false,
  //   data: {},
  // });
  // const [isGetProfit, setIsGetProfit] = useState({
  //   open: false,
  //   data: {},
  // });
  // const [isEarning, setIsEarning] = useState({
  //   open: false,
  //   data: {},
  // });

  const [isStaking, setIsStaking] = useState({
    open: false,
    data: {},
  });

  const [collapse, setCollapse] = useState<any>({});
  const [collapseSub, setCollapseSub] = useState<any>({});
  // const [active, setActive] = useState<any>(null);

  const getListProducts = async (params?: any) => {
    try {
      const resp: any = await sendApiRequest({
        ...apiConfig.products.getList,
        body: {
          notHighlight: 1,
          ...tableParams,
          ...params,
        },
      });
      if (resp?.result) {
        const data = resp.data?.additionalProperties;
        setTotalElement(resp?.data?.pageable?.totalElement || null);
        setDataProducts(data);

        //handle sub data normal
        if (collapseSub?.assetType) {
          const dtGetGroupByRanking = data?.find(
            (item: any) => item?.assetType === collapseSub?.assetType
          );
          const dtSubNormal = dtGetGroupByRanking?.groupByRanking?.find(
            (item: any) =>
              item?.limitMembershipGrade ===
              collapseSub?.listProduct[0]?.limitMembershipGrade
          );
          setCollapseSub({ ...collapseSub, ...dtSubNormal });
        }
      }
    } catch (error) {
      setDataProducts([]);
      setTotalElement(null);
    }
  };

  const handleIconCoin = (assetType: string) => {
    const findItem = dataListCoin?.find(
      (v: { assetSymbol: any }) => v?.assetSymbol === assetType
    );

    return findItem?.assetIcon;
  };

  useEffect(() => {
    if (dataListCoin?.length > 0) getListProducts(dataFilters);
  }, [dataListCoin, isAuth, dataFilters]);

  // const getListStartEarning = (dataProduct: any) => {
  //   const dtFilter: any = dataProducts.find(
  //     (item: any) => item?.assetType === dataProduct?.assetType
  //   );

  //   const stakingEndTime = new Date(dataProduct?.userStakingEndTime);
  //   const timeNow = new Date();

  //   const listStartEarning = dtFilter?.listProduct?.filter(
  //     (item: any) =>
  //       dataUser?.membershipLevel < item?.limitMembershipLevel ||
  //       (!(
  //         item?.stakingStatus === 2 ||
  //         (item?.stakingStatus === 1 &&
  //           stakingEndTime.getTime() <= timeNow.getTime())
  //       ) &&
  //         moment(item?.startAt).isBefore(moment()))
  //   );

  //   setIsStartEarning({
  //     open: true,
  //     data: {
  //       ...dataProduct,
  //       listStartEarning: listStartEarning,
  //     },
  //   });
  // };

  // const renderButton = (item: any, listItem?: any) => {
  //   if (dataUser?.membershipLevel < item?.limitMembershipLevel) {
  //     return (
  //       <button
  //         className="sm:w-[129px] w-full text-[14px] text-[#060606] font-semibold border-0 cursor-pointer hover:opacity-70 bg-[#B0F127] h-[32px] rounded-full disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
  //             disabled:cursor-not-allowed"
  //         onClick={
  //           () =>
  //             !isAuth
  //               ? navigate(UrlInternal.LOGIN)
  //               : setIsStaking({
  //                   open: true,
  //                   data: {
  //                     dataClick: item,
  //                     listProduct: listItem,
  //                     statusStaking: statusStaking.START_EARNING,
  //                   },
  //                 })
  //           // : navigate(UrlInternal.MEMBERSHIP_NFT)
  //         }
  //       >
  //         Upgrade
  //       </button>
  //     );
  //   }
  //   const stakingEndTime = new Date(item?.userStakingEndTime);
  //   const timeNow = new Date();
  //   if (
  //     item?.stakingStatus === 2 ||
  //     (item?.stakingStatus === 1 &&
  //       stakingEndTime.getTime() <= timeNow.getTime())
  //   )
  //     return (
  //       <button
  //         onClick={() =>
  //           !isAuth
  //             ? navigate(UrlInternal.LOGIN)
  //             : setIsStaking({
  //                 open: true,
  //                 data: {
  //                   dataClick: item,
  //                   listProduct: listItem,
  //                   statusStaking: statusStaking.GET_PROFIT,
  //                 },
  //               })
  //         }
  //         className="sm:w-[129px] w-full text-[14px] font-semibold border border-solid border-[#fff] text-[#fff] cursor-pointer hover:opacity-70 bg-[transparent] h-[32px] px-5 rounded-full"
  //       >
  //         Get profit
  //       </button>
  //     );
  //   if (
  //     item?.stakingStatus === 1 &&
  //     stakingEndTime.getTime() > timeNow.getTime()
  //   )
  //     return (
  //       <button
  //         onClick={() =>
  //           !isAuth
  //             ? navigate(UrlInternal.LOGIN)
  //             : setIsStaking({
  //                 open: true,
  //                 data: {
  //                   dataClick: item,
  //                   listProduct: listItem,
  //                   statusStaking: statusStaking.EARNING,
  //                 },
  //               })
  //         }
  //         className="sm:w-[129px] w-full text-[14px] font-semibold border border-solid border-[#B0F127] text-[#B0F127] cursor-pointer hover:opacity-70 bg-[transparent] h-[32px] px-5 rounded-full"
  //       >
  //         Earning
  //       </button>
  //     );
  //   return (
  //     <>
  //       {moment(item?.startAt).isBefore(moment()) ? (
  //         <button
  //           className="sm:w-[129px] w-full text-[14px] text-[#060606] font-semibold border-0 cursor-pointer hover:opacity-70 bg-[#B0F127] h-[32px] rounded-full disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
  //             disabled:cursor-not-allowed"
  //           onClick={() =>
  //             !isAuth
  //               ? navigate(UrlInternal.LOGIN)
  //               : setIsStaking({
  //                   open: true,
  //                   data: {
  //                     dataClick: item,
  //                     listProduct: listItem,
  //                     statusStaking: statusStaking.START_EARNING,
  //                   },
  //                 })
  //           }
  //           disabled={item?.limitTotalStaking === item?.totalStaking}
  //         >
  //           Start earning
  //         </button>
  //       ) : (
  //         <button
  //           className="sm:w-[129px] w-full text-[14px] text-[#060606] font-semibold border-0 cursor-pointer hover:opacity-70 bg-[#fff] h-[32px] rounded-full"
  //           onClick={() =>
  //             !isAuth
  //               ? navigate(UrlInternal.LOGIN)
  //               : setIsStaking({
  //                   open: true,
  //                   data: {
  //                     dataClick: item,
  //                     listProduct: listItem,
  //                     statusStaking: statusStaking.START_EARNING,
  //                   },
  //                 })
  //           }
  //         >
  //           Coming soon
  //         </button>
  //       )}
  //     </>
  //   );
  // };

  const handleGrade = (limitMembershipGrade: string) => {
    const findItem = listGrade?.find(
      (item: any) => item?.grade === limitMembershipGrade
    );

    return findItem;
  };

  return (
    <Wrapper>
      <ModalStaking
        isVisible={isStaking.open}
        setCancel={() => setIsStaking({ open: false, data: {} })}
        data={isStaking.data}
        onSuccess={() => getListProducts()}
      />
      {/* <StartEarning
        isVisible={isStartEarning.open}
        setCancel={() => setIsStartEarning({ open: false, data: {} })}
        data={isStartEarning.data}
        onSuccess={() => getListProducts()}
      />
      <Earning
        isVisible={isEarning.open}
        setCancel={() => setIsEarning({ open: false, data: {} })}
        data={isEarning.data}
        onSuccess={() => getListProducts()}
      />
      <GetProfit
        isVisible={isGetProfit.open}
        setCancel={() => setIsGetProfit({ open: false, data: {} })}
        data={isGetProfit.data}
        onSuccess={() => getListProducts()}
      /> */}

      {dataProducts?.length > 0 ? (
        <div className="grid w-full text-white">
          {/* Header */}
          <div className="lg:grid grid-cols-[1fr_1fr_52px] lg:grid-cols-[350px_1fr_1fr_1fr_1fr] bg-[#121212] font-bold hidden">
            <div className="py-3 px-6">Coins</div>
            <div className="py-3 px-6">Est. APR</div>
            <div className="py-3 px-6">Duration</div>
            <div className="py-3 px-6">Subscription Amount</div>
            <div></div>
          </div>

          {/* Rows pc*/}
          <div className="lg:grid grid-rows-[auto] hidden gap-3">
            {dataProducts.map((item: any) => {
              return (
                <div key={item.assetType}>
                  <div
                    className={`grid grid-cols-[1fr_24px] grid-rows-[auto_auto] lg:grid-cols-[350px_1fr_1fr_1fr_1fr] transition duration-200 border border-solid border-[#FFFFFF40] rounded-[18px] bg-gradient-product`}
                  >
                    <div className={`py-5 px-6 flex items-center gap-[10px]`}>
                      <img
                        src={handleIconCoin(item?.assetType)}
                        alt="img"
                        className="w-[28px]"
                      />
                      <div>{item.assetType}</div>
                    </div>
                    <div
                      className={`text-[#B0F127] text-[15px] py-5 px-6 hidden lg:block`}
                    >
                      {item?.minMaxApr}
                    </div>
                    <div className={`py-5 px-6 hidden lg:block`}>
                      {item?.minMaxDaysDuration}
                    </div>
                    <div className={`py-5 px-6 hidden lg:block`}></div>
                    <div
                      className="cursor-pointer hover:opacity-70 flex items-center justify-center"
                      onClick={() => {
                        setCollapseSub({});
                        setCollapse((prev: any) => {
                          if (item?.assetType === prev?.assetType) return {};
                          return item;
                        });
                      }}
                    >
                      <img
                        src={CaretDown}
                        className={`transition-transform duration-300 ${
                          collapse?.assetType === item?.assetType
                            ? "rotate-180"
                            : "rotate-0"
                        }`}
                      />
                    </div>
                  </div>

                  {collapse?.assetType === item?.assetType && (
                    <div className="lg:flex hidden flex-col gap-2 pt-2 mx-6">
                      {item?.groupByRanking?.map((itemSub: any) => (
                        <div
                          className="border border-solid border-[#FFFFFF40] rounded-[18px]"
                          key={itemSub?.id}
                        >
                          <div className="grid grid-cols-[1fr_1fr_52px] grid-rows-[auto_auto] md:grid-cols-[350px_1fr_1fr_1fr_1fr]">
                            <div className="flex items-center gap-[8px] py-[18px] px-6">
                              {handleGrade(itemSub?.limitMembershipGrade)
                                ?.level > 0 && (
                                <img
                                  src={
                                    dataRank[
                                      handleGrade(itemSub?.limitMembershipGrade)
                                        ?.level - 1
                                    ]
                                  }
                                  alt="img"
                                />
                              )}
                              <div className="text-[#EAECEF] font-montserrat500">
                                {
                                  handleGrade(itemSub?.limitMembershipGrade)
                                    ?.gradeName
                                }
                              </div>
                            </div>
                            <div
                              className={`text-[#B0F127] text-[15px] py-[18px] px-6`}
                            >
                              {itemSub?.minMaxApr}
                            </div>
                            <div className={`py-[18px] px-6 hidden md:block`}>
                              {itemSub?.minMaxDaysDuration}
                            </div>
                            <div
                              className={`px-6 hidden md:flex flex-col justify-center`}
                            >
                              {/* <Progress
                                value={itemSub?.totalStaking}
                                valueTarget={itemSub?.limitTotalStaking}
                                isComingSoon={moment(itemSub?.startAt).isAfter(
                                  moment()
                                )}
                              /> */}
                            </div>
                            <div
                              className={`px-6 hidden md:flex justify-center items-center`}
                            >
                              {itemSub?.limitMembershipGrade === "NORMAL" ? (
                                <div
                                  className="cursor-pointer hover:opacity-70 flex items-center justify-center"
                                  onClick={() => {
                                    setCollapseSub((prev: any) => {
                                      if (
                                        itemSub?.assetType === prev?.assetType
                                      )
                                        return {};
                                      return itemSub;
                                    });
                                  }}
                                >
                                  <img
                                    src={CaretDown}
                                    className={`transition-transform duration-300 ${
                                      collapseSub?.assetType ===
                                      itemSub?.assetType
                                        ? "rotate-180"
                                        : "rotate-0"
                                    }`}
                                  />
                                </div>
                              ) : (
                                <button
                                  className="sm:w-[129px] w-full text-[14px] text-[#060606] font-semibold border-0 cursor-pointer hover:opacity-70 bg-[#B0F127] h-[32px] rounded-full disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
              disabled:cursor-not-allowed"
                                  onClick={() =>
                                    !isAuth
                                      ? navigate(UrlInternal.LOGIN)
                                      : setIsStaking({
                                          open: true,
                                          data: {
                                            dataClick: itemSub?.listProduct[0],
                                            listProduct: itemSub?.listProduct,
                                            statusStaking:
                                              handleCheckStatusStaking(
                                                itemSub?.listProduct[0]
                                              ),
                                          },
                                        })
                                  }
                                  // disabled={
                                  //   item?.limitTotalStaking === item?.totalStaking
                                  // }
                                >
                                  Start earning
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {collapseSub?.assetType === item?.assetType && (
                    <div className="lg:flex hidden flex-col mt-2 mx-6 border border-solid border-[#FFFFFF40] rounded-[18px]">
                      {collapseSub?.listProduct?.map((itemSub: any) => (
                        <div className="" key={itemSub?.id}>
                          <div className="grid grid-cols-[1fr_1fr_52px] grid-rows-[auto_auto] md:grid-cols-[350px_1fr_1fr_1fr_1fr]">
                            <div className="py-[16px] px-6 text-[#EAECEF] font-montserrat500">
                              {itemSub?.productName}
                            </div>
                            <div
                              className={`text-[#B0F127] text-[15px] py-[16px] px-6`}
                            >
                              {formatPrice(itemSub?.apr * 100)}%
                            </div>
                            <div className={`py-[16px] px-6 hidden md:block`}>
                              {itemSub?.daysDuration} days
                            </div>
                            <div
                              className={`px-6 hidden md:flex flex-col justify-center`}
                            >
                              <Progress
                                value={itemSub?.totalStaking}
                                valueTarget={itemSub?.limitTotalStaking}
                                isComingSoon={moment(itemSub?.startAt).isAfter(
                                  moment()
                                )}
                              />
                            </div>
                            <div
                              className={`px-6 hidden md:flex justify-center items-center`}
                            >
                              {/* {renderButton(itemSub, collapseSub?.listProduct)} */}
                              <ButtonStatusStaking
                                item={itemSub}
                                listItem={collapseSub?.listProduct}
                                setIsStaking={setIsStaking}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          {/**Rows mobile */}
          <div className="lg:hidden flex flex-col gap-[8px]">
            {dataProducts.map((item: any) => {
              return (
                <div key={item?.id}>
                  <div
                    className={`p-4 border border-solid border-[#FFFFFF40] rounded-[18px] bg-gradient-product`}
                  >
                    <div className="flex justify-between items-center">
                      <div className={`flex items-center gap-[10px]`}>
                        <img
                          src={handleIconCoin(item?.assetType)}
                          alt="img"
                          className="w-[28px]"
                        />
                        <div>{item.assetType}</div>
                      </div>
                      <div
                        className="cursor-pointer hover:opacity-70 flex items-center justify-center"
                        onClick={() =>
                          setCollapse((prev: any) => {
                            if (item?.assetType === prev?.assetType) return {};
                            return item;
                          })
                        }
                      >
                        <img
                          src={CaretDown}
                          className={`transition-transform duration-300 ${
                            collapse?.assetType === item?.assetType
                              ? "rotate-180"
                              : "rotate-0"
                          }`}
                        />
                      </div>
                    </div>
                    <div className="flex mt-[12px]">
                      <div className="flex-1">
                        <div className="text-[#B0F127] font-montserrat700">
                          {item?.minMaxApr}
                        </div>
                      </div>
                      <div className="flex-1">
                        <div className="text-[#EAECEF] font-montserrat700">
                          {item?.minMaxDaysDuration}
                        </div>
                      </div>
                    </div>
                  </div>

                  {collapse?.assetType === item?.assetType && (
                    <div className="flex flex-col gap-[8px] mt-2">
                      {item?.groupByRanking?.map((itemSub: any) => (
                        <div
                          className={`p-4 border border-solid border-[#FFFFFF40] rounded-[18px] bg-gradient-product`}
                        >
                          <div className="flex justify-between items-center">
                            <div className={`flex items-center gap-[10px]`}>
                              {handleGrade(itemSub?.limitMembershipGrade)
                                ?.level > 0 && (
                                <img
                                  src={
                                    dataRank[
                                      handleGrade(itemSub?.limitMembershipGrade)
                                        ?.level - 1
                                    ]
                                  }
                                  alt="img"
                                />
                              )}
                              <div className="text-[#EAECEF] font-montserrat500">
                                {
                                  handleGrade(itemSub?.limitMembershipGrade)
                                    ?.gradeName
                                }
                              </div>
                            </div>
                            {itemSub?.limitMembershipGrade === "NORMAL" && (
                              <div
                                className="cursor-pointer hover:opacity-70 flex items-center justify-center"
                                onClick={() =>
                                  setCollapseSub((prev: any) => {
                                    if (itemSub?.assetType === prev?.assetType)
                                      return {};
                                    return itemSub;
                                  })
                                }
                              >
                                <img
                                  src={CaretDown}
                                  className={`transition-transform duration-300 ${
                                    collapseSub?.assetType ===
                                    itemSub?.assetType
                                      ? "rotate-180"
                                      : "rotate-0"
                                  }`}
                                />
                              </div>
                            )}
                          </div>
                          {itemSub?.limitMembershipGrade !== "NORMAL" && (
                            <div className="flex mt-[12px] items-center justify-between">
                              <div className="text-[#B0F127] font-montserrat700 text-[14px]">
                                {itemSub?.minMaxApr}
                              </div>
                              <div className="text-[#EAECEF] font-montserrat700 text-[14px]">
                                {itemSub?.minMaxDaysDuration}
                              </div>
                              <button
                                className="w-[118px] text-[14px] text-[#060606] font-semibold border-0 cursor-pointer hover:opacity-70 bg-[#B0F127] h-[32px] rounded-full disabled:bg-[#4B4D47] disabled:border-[#4B4D47]
              disabled:cursor-not-allowed"
                                onClick={() =>
                                  !isAuth
                                    ? navigate(UrlInternal.LOGIN)
                                    : setIsStaking({
                                        open: true,
                                        data: {
                                          dataClick: itemSub?.listProduct[0],
                                          listProduct: itemSub?.listProduct,
                                          statusStaking:
                                            handleCheckStatusStaking(
                                              itemSub?.listProduct[0]
                                            ),
                                        },
                                      })
                                }
                                // disabled={
                                //   item?.limitTotalStaking === item?.totalStaking
                                // }
                              >
                                Start earning
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}

                  {collapseSub?.assetType === item?.assetType && (
                    <div className="mt-2 border border-solid border-[#FFFFFF40] rounded-[18px]">
                      {collapseSub?.listProduct?.map(
                        (itemSub: any, indexSub: number) => (
                          <div
                            className={`border-0 border-t border-solid border-[#2F2F2F] p-4 pb-5 ${
                              indexSub === 0 && "border-none"
                            }`}
                            key={itemSub?.id}
                          >
                            <div className="flex items-center gap-[8px]">
                              <div className="text-[#EAECEF] font-montserrat500">
                                {itemSub?.productName}
                              </div>
                            </div>
                            <div className="flex my-[10px] items-center justify-between">
                              <div className="text-[#C0C0C0] text-[14px]">
                                Est. APR:{" "}
                                <div className="text-[#B0F127] font-montserrat500 inline-block">
                                  {formatPrice(itemSub?.apr * 100)}%
                                </div>
                              </div>
                              <div className="text-[#C0C0C0] text-[14px]">
                                Duration:{" "}
                                <div className="text-[#EAECEF] font-montserrat500 inline-block">
                                  {itemSub?.daysDuration}D
                                </div>
                              </div>
                            </div>
                            <Progress
                              value={itemSub?.totalStaking}
                              valueTarget={itemSub?.limitTotalStaking}
                              isComingSoon={moment(itemSub?.startAt).isAfter(
                                moment()
                              )}
                            />
                            <div className="mt-3">
                              {/* {renderButton(itemSub, collapseSub?.listProduct)} */}
                              <ButtonStatusStaking
                                item={itemSub}
                                listItem={collapseSub?.listProduct}
                                setIsStaking={setIsStaking}
                              />
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <NotFound />
      )}
      {/* {totalElement && totalElement > dataProducts?.length && (
        <div
          className=" text-[16px] font-[500] underline text-center mt-8"
          onClick={() =>
            getListProducts({
              ...dataFilters,
              size: undefined,
            })
          }
        >
          View More
        </div>
      )} */}
    </Wrapper>
  );
};

export default ListCoin;
