import { statusStaking } from "@/constant/statusStaking";
import { handleCheckStatusStaking } from "@/utils/handleCheckStatusStaking";
import { useState } from "react";
import ModalBase from "../ModalBase";
import SliderStartEarning from "../SliderStartEarning";
import Earning from "./Earning";
import GetProfit from "./GetProfit";
import StartEarning from "./StartEarning";

interface Props {
  isVisible: boolean;
  setCancel?: () => void;
  data: any;
  onSuccess?: () => void;
}

function ModalStaking({ isVisible, setCancel, data, onSuccess }: Props) {
  const [dataActive, setDataActive] = useState<any>();
  const valueStatusStaking = dataActive?.statusStaking || data?.statusStaking;

  console.log("ModalStaking", data);

  return (
    <ModalBase
      isVisible={isVisible}
      setCancel={setCancel}
      // title={handleCheckTitle()}
    >
      <SliderStartEarning
        dataClick={dataActive || data?.dataClick}
        data={data?.listProduct}
        onClick={(value) =>
          setDataActive({
            ...value,
            statusStaking: handleCheckStatusStaking(value),
          })
        }
      />

      <div className="mt-[41px] sm:pb-0 pb-[50px]">
        {valueStatusStaking === statusStaking.START_EARNING && (
          <StartEarning
            onSuccess={onSuccess}
            setCancel={setCancel}
            data={dataActive || data?.dataClick}
          />
        )}
        {valueStatusStaking === statusStaking.EARNING && (
          <Earning
            onSuccess={onSuccess}
            setCancel={setCancel}
            data={dataActive || data?.dataClick}
          />
        )}
        {valueStatusStaking === statusStaking.GET_PROFIT && (
          <GetProfit
            onSuccess={onSuccess}
            setCancel={setCancel}
            data={dataActive || data?.dataClick}
          />
        )}
      </div>
    </ModalBase>
  );
}

export default ModalStaking;
