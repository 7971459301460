import Img from "@/assets/images/about/milestones.svg";

function Milestones() {
  return (
    <div className="max-w-[1313px] px-4 mx-auto w-full leading-none">
      <div className="lg:text-[54px] text-[32px] font-montserrat600 lg:mb-[55px] mb-[30px]">
        Milestones & <span className="text-[#B0F127]">What’s Next</span>
      </div>

      <div className="flex flex-col gap-[25px]">
        <div className="flex items-center gap-[15px] xl:max-w-[830px] max-w-[600px] w-full border border-solid border-[#606060] rounded-[25px] py-[21px] md:px-[30px] px-[20px]">
          <img src={Img} alt="img" />
          <div className="md:text-[24px] text-[20px] font-montserrat500">
            Real-world arbitrage performance already proven
          </div>
        </div>
        <div className="flex items-center gap-[15px] xl:max-w-[830px] max-w-[600px] w-full border border-solid border-[#606060] rounded-[25px] py-[21px] md:px-[30px] px-[20px]">
          <img src={Img} alt="img" />
          <div className="md:text-[24px] text-[20px] font-montserrat500">
            NFT launch & exclusive airdrop preparation underway
          </div>
        </div>
        <div className="flex items-center gap-[15px] xl:max-w-[830px] max-w-[600px] w-full border border-solid border-[#606060] rounded-[25px] py-[21px] md:px-[30px] px-[20px]">
          <img src={Img} alt="img" />
          <div className="md:text-[24px] text-[20px] font-montserrat500">
            Expansion to global exchange partners in progress
          </div>
        </div>
        <div className="flex items-center gap-[15px] xl:max-w-[830px] max-w-[600px] w-full border border-solid border-[#606060] rounded-[25px] py-[21px] md:px-[30px] px-[20px]">
          <img src={Img} alt="img" />
          <div className="md:text-[24px] text-[20px] font-montserrat500">
            Enhanced smart contract automation & security layers
          </div>
        </div>
        <div className="flex items-center gap-[15px] xl:max-w-[830px] max-w-[600px] w-full border-[1.5px] border-solid border-[#B0F127] rounded-[25px] py-[21px] pl-[19px] bg-milestones">
          <img src={Img} alt="img" />
          <div className="md:text-[24px] text-[20px] font-montserrat700 text-[#B0F127]">
            Launching a points-based rewards system for user engagement
          </div>
        </div>
      </div>
      <div className="mt-[15px] text-[#B7B7B7] text-[20px] font-montserrat500 xl:max-w-[830px] max-w-[600px]">
        → Points will be swappable with Defylo’s native token, enabling real
        value redemption
      </div>
    </div>
  );
}

export default Milestones;
