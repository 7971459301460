import ImgBanner from "@/assets/images/about/banner_about.svg";

function Banner() {
  return (
    <div className="lg:mt-[95px] mt-10 max-w-[1313px] px-4 w-full mx-auto banner">
      <div className="relative">
        <div className="sm:text-[66px] text-[31px] max-w-[550px] font-BlackOpsOne lg:pt-[20px] lg:pb-[150px] lg:text-left text-center lg:mx-0 mx-auto leading-none uppercase">
          <div className="text-[#B0F127]">
            The Future of <br /> Finance
          </div>
          Powered by <br />
          AI, Driven by DeFi
        </div>
        <img
          src={ImgBanner}
          alt="ImgBanner"
          className="lg:absolute top-0 right-[-125px] lg:m-0 mx-auto mt-[35px] mb-[65px]"
        />
      </div>

      <div className="border-gradient-banner border border-solid border-transparent flex items-center sm:flex-row flex-col rounded-[30px] mt-[15px] mb-[60px]">
        <div className="max-w-[280px] w-full flex items-center justify-center">
          <div className="text-wrap lg:text-[44px] text-[26px] font-BlackOpsOne lg:max-w-[110px] leading-none py-[20px] uppercase">
            Who We Are
          </div>
        </div>
        <div className="md:px-[45px] px-[21.5px] flex-1 text-[18px] font-montserrat500 sm:text-left text-center text-[#D8D8D8] md:py-[57.5px] py-[30px] border-gradient-banner border-0 sm:border-l border-t border-solid border-transparent sm:rounded-r-[30px] rounded-b-[30px]">
          Smarter Finance. For Everyone. <br /> Defylo is an AI-driven
          decentralized finance (DeFi) ecosystem designed to unlock yield
          opportunities across global markets — securely, automatically, and
          intelligently.
          <br /> We simplify the complexities of DeFi and arbitrage into
          seamless earning tools accessible to both newcomers and seasoned
          investors.
        </div>
      </div>
    </div>
  );
}

export default Banner;
