import Img from "@/assets/images/about/benefit.svg";
import ImgPrice from "@/assets/images/about/benefit-price.svg";

const data = [
  "Black Sovereign  +200% yield boost, top-tier airdrops, exclusive program access",
  "Diamond Prestige  Priority staking pools, enhanced rewards",
  "Gold Prime  Early access to DeFi campaigns, premium support",
  "Silver Reserve  Yield enhancement, network-wide utility",
];

function Benefits() {
  return (
    <div className="benefit bg-gradient-benefit">
      <div className="max-w-[1540px] px-4 mx-auto w-full flex items-center justify-between xl:gap-[23px] gap-[30px] xl:flex-row flex-col xl:py-0 py-[50px]">
        <div className="xl:pl-[112px]">
          <div className="md:text-[54px] text-[32px] font-montserrat600 md:text-left text-center">
            Membership <span className="text-[#B0F127]">NFT System</span>
          </div>
          <div className="text-[#898989] font-montserrat500 text-[20px] mt-[5px] mb-[32px] md:text-left text-center">
            Gain elite access to Defylo’s premium ecosystem through our 4-tier
            NFT membership program
          </div>
          <div className="text-[#B0F127] text-[24px] font-montserrat700 pb-[15px] border-0 border-b border-solid border-[#303030]">
            Tier Benefits
          </div>
          {data?.map((dt, i) => (
            <div
              key={i}
              className="flex items-center gap-[15px] py-5 border-0 border-b border-solid border-[#303030]"
            >
              <img src={ImgPrice} alt="price" />
              <div className="font-montserrat600 text-[20px] xl:pr-2">{dt}</div>
            </div>
          ))}
          <div className="text-[#B0F127] mt-[18px] md:max-w-[540px] md:text-left text-center">
            Limited Supply. Maximum Utility. Your NFT isn’t just a membership —
            it’s your key to higher earning power in the Defylo ecosystem.
          </div>
        </div>
        <img src={Img} alt="img" />
      </div>
    </div>
  );
}

export default Benefits;
